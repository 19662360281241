import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Hero from '../components/hero';

function NotFound({}) {
  return (
    <Layout>
      <div
        style={{
          backgroundImage: 'url(https://source.unsplash.com/kZO9xqmO_TA/2000x1000)',
          height: '100vh',
          backgroundSize: 'cover',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', top: '25%', width: '100%' }}>
          <div className="container">
            <div className="has-text-white" style={{ maxWidth: 768 }}>
              <h1 className="title is-1 has-text-white">404: Not Found!</h1>
              <p className="subtitle is-4 has-text-white">
                Looks like you've landed on a page that doesn't exist.{' '}
              </p>
              <Link className="button is-medium" to="/">
                Take me Home!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

NotFound.propTypes = {};

export default NotFound;
